import React, { Component } from 'react'
//import {BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageMainComponent from "./component/PageMainComponent";
//import PageSimpleComponent from "./component/PageSimpleComponent";
import NotFoundComponent from "./component/NotFoundComponent";

class App extends Component{

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/nissy/:targetDate/:currentDate?' component={PageMainComponent} />
          <Route component={NotFoundComponent} />
        </Switch>
      </Router>
    );
  }
}

export default App;