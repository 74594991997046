// ライブ日時の取得クラス
class DaysData {
  // コンストラクタ
  constructor(api) {
    this.api = api;

    this.liveItems = {};
  }

  // 日付のフォーマットをそろえる
  setFormatDate(data) {
    let date = new Date(data);
    // 年
    var year = date.getFullYear();
    // 月
    var  month = ("0" + (date.getMonth() + 1)).slice(-2);
    // 日
    var  day =  ("0" + date.getDate()).slice(-2);

    return year + month + day;   
  };

  // ライブ日時情報を取得
  getData() {
    fetch(this.api)
    .then(res => res.text())
    .then(data => {
      // 行事のデータを取得
      var dataItems = data.split('\n');

      for (const dataItem of dataItems) {
        // 項目事のデータを取得
        var datas = dataItem.split('|');
        if (datas.length !== 1) {
          var liveDate = this.setFormatDate(datas[1]);
          this.liveItems[liveDate] = {};
          this.liveItems[liveDate]['name'] = datas[0];
          this.liveItems[liveDate]['date'] = liveDate;
          this.liveItems[liveDate]['start_date'] = this.setFormatDate(datas[2]);
          this.liveItems[liveDate]['end_date'] = this.setFormatDate(datas[3]);
        }
      }

    });
  }

}

export default DaysData;
