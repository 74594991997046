import React from 'react'

class DetailComponent extends React.Component {

  constructor(props){
    super(props);
    this.item = props.item;
  }

  render(){
    return(
      <div>
        <p class="note"></p>

        <h1 dangerouslySetInnerHTML={{__html: this.item.goods_name}}></h1>

        <div class="pic" style={{backgroundImage: `url("${this.item.goods_image}")`}}></div>

        <p class="note" dangerouslySetInnerHTML={{__html: this.item.description}}></p>

      <table>
         {this.item.size_id.map((size_id) => (
            <tbody>
                <tr>
                    <th dangerouslySetInnerHTML={{__html: size_id.size_name}}></th>
                    <td class={size_id.soldout}></td>
                  <td>
                    <p>{Number(size_id.price).toLocaleString()}円<span>（税込）</span></p>
                  </td>
                </tr>
            </tbody>
          ))}
      </table>

      <p class="ex" dangerouslySetInnerHTML={{__html: this.item.extra}}></p>
      <br></br>
      </div>

    );
  }

}

export default DetailComponent;
