// 特別な商品取得クラス
class SpecialData {
  // コンストラクタ
  constructor(apis) {
    // 特別な商品を取得するAPI
    this.api = apis['specialApi'];
    // mu-mo SHOPの商品を取得するAPI
    this.lambdaMumoApi = apis['mumoShopApi'];

    // 特別な商品を表示する場合、フラグをtrueとする
    this.specialFlg = true;
    this.specialData = [];
    this.specialGoodItems = {};
  }

  // 特別な商品を取得
  getSpecialData = () => fetch(this.api).then(res => res.text());

  // mu-mo SHOPの商品を取得
  getMumoShopApi(merc_no){
    return fetch(this.lambdaMumoApi+'?merc_no='+merc_no).then(res => res.json());
  }

  // mu-mo SHOPの商品情報を取得
  getMumoShopItem(datas, mumoShopItemData, cnt) {
    // 値を格納する変数
    let mumoShopItem = {}

    // スプレットシートの項目順番を取得
    mumoShopItem['item_no'] = cnt;
    // 画面表示用グッズ名
    mumoShopItem['goods_name'] = datas[3] !== '' ? datas[3] : mumoShopItemData.name;
    // 詳細画面用商品説明文
    mumoShopItem['description'] = datas[4] !== '' ? datas[4] : mumoShopItemData.merc_desc;

    // グッズ画像ファイル名
    if (datas[5] !== '') {
      // httpsから始まる画像が登録されていた場合、httpsから画像を参照
      mumoShopItem['goods_image'] = datas[5].startsWith('https') ? datas[5] : "/assets/images/" + datas[5];
    } else {
      mumoShopItem['goods_image'] = mumoShopItemData.images[0].image_url;
    }

    // 売り切れフラグ
    let soldout = '';
    // 表示しない場合
    if (datas[0] === '0') {
      soldout = 'soldout';
    }

    // 売り切れ表示
    mumoShopItem['soldout'] = soldout;

    return mumoShopItem;
  }

  // 商品情報を取得
  async getData() {
    // 特別な商品を取得
    const data = await this.getSpecialData();

    // スプレットシートの項目順番
    let cnt = 1;

    // 行事のデータを取得
    let dataItems = data.split('\n');

    // スプレットシートの行事のデータを格納
    for (let dataItem of dataItems) {
      // 項目事のデータを取得
      let datas = dataItem.split('|');

      // データが存在するときに
      if (datas.length !== 1) {
        // グッズIDを取得
        let goods_id = datas[1];
        // mu-mo shopの商品番号
        let merc_no = datas[2];

        if (merc_no !== '') {

          ////////////////////////////
          // mu-mo SHOPの商品番号が指定されている場合
          ////////////////////////////

          // 同一goods_idが存在していな場合
          // ※sku個別商品で検索を行っても、sku商品としてデータを取得します
          if (!(goods_id in this.specialGoodItems)) {
            // mu-mo SHOP の商品情報を取得
            let mumoShopItemData = await this.getMumoShopApi(merc_no);

            // ステータスが正常(0)の時
            if (mumoShopItemData.status === 0) {
              // mu-mo SHOPの商品情報を取得
              this.specialGoodItems[goods_id] = this.getMumoShopItem(datas, mumoShopItemData, cnt);

              cnt++;
            }
          }

        } else {

          ////////////////////////////
          // 新規商品として設定
          ////////////////////////////

          this.specialGoodItems[goods_id] = {};
          // スプレットシートの項目順番を取得
          this.specialGoodItems[goods_id]['item_no'] = cnt;
           // 画面表示用グッズ名
          this.specialGoodItems[goods_id]['goods_name'] = datas[3];

          // 売り切れフラグ
          let soldout = '';
          // 表示しない場合
          if (datas[0] === '0') {
            soldout = 'soldout';
          }

          // 売り切れ表示
          this.specialGoodItems[goods_id]['soldout'] = soldout;

          // 詳細画面用商品説明文
          this.specialGoodItems[goods_id]['description'] = datas[4];
          // グッズ画像ファイル名
          // httpsから始まる画像が登録されていた場合、httpsから画像を参照
          this.specialGoodItems[goods_id]['goods_image'] = datas[5].startsWith('https') ? datas[5] : "/assets/images/" + datas[5];

          cnt++;
        }
      }

    }

    // 商品の中で、スプレットシートの項目順番に該当する商品の値を取得
    for (let goods_id in this.specialGoodItems) {
      // 商品の追加
      this.specialData.push(this.specialGoodItems[goods_id]);
    }

  }

}

export default SpecialData;
