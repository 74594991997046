// 普通の商品取得クラス
class NormalData {
  // コンストラクタ
  constructor(apis) {
    // 普通の商品を取得するAPI
    this.api = apis['normalApi'];
    // mu-mo SHOPの商品を取得するAPI
    this.lambdaMumoApi = apis['mumoShopApi'];

    // 最終的に値を格納する配列
    this.normalData = [];
    // 一時的に値を格納する連想配列
    this.normalGoodItems = {};
  }

  // 普通の商品を取得
  getNomalData = () => fetch(this.api).then(res => res.text());

  // mu-mo SHOPの商品を取得
  getMumoShopApi(merc_no){
    return fetch(this.lambdaMumoApi+'?merc_no='+merc_no).then(res => res.json());
  }

  // mu-mo SHOPの商品情報を取得
  getMumoShopItem(datas, mumoShopItemData, cnt) {
    // 値を格納する変数
    let mumoShopItem = {}

    // スプレットシートの項目順番を取得
    mumoShopItem['item_no'] = cnt;
    // 画面表示用グッズ名
    mumoShopItem['goods_name'] = datas[3] !== '' ? datas[3] : mumoShopItemData.name;
    // 詳細画面用商品説明文
    mumoShopItem['description'] = datas[7] !== '' ? datas[7] : mumoShopItemData.merc_desc;
    // 詳細画面用商品説明文 その２
    mumoShopItem['extra'] = datas[8] !== '' ? datas[8] : mumoShopItemData.spec_desc;

    // グッズ画像ファイル名
    if (datas[9] !== '') {
      // httpsから始まる画像が登録されていた場合、httpsから画像を参照
      mumoShopItem['goods_image'] = datas[9].startsWith('https') ? datas[9] : "/assets/images/" + datas[9];
    } else {
      mumoShopItem['goods_image'] = mumoShopItemData.images[0].image_url;
    }

    // 売り切れフラグ
    let soldout = '';
    // 表示しない場合
    if (datas[0] === '0') {
      soldout = 'soldout';
    }

    // グッズサイズ
    mumoShopItem['size_id'] = [];

    // 販売価格を取得
    let sale_price = mumoShopItemData.sale_price;

    // 複数の商品が存在するとき
    if (mumoShopItemData.sku != null) {
      for (let skuItem of mumoShopItemData.sku.items) {
        // sku商品(サイズの選べる商品)のサイズ情報を取得
        mumoShopItem['size_id'].push(
          {
            'size_id':skuItem.size,
            'size_name':skuItem.opt.split('/')[0],
            'price':sale_price,
            'soldout':soldout
            //'soldout':soldout !== '' ? soldout : skuItem.stamp
          }
        );

      }
    } else {
      // 単一商品のサイズ情報を取得
      mumoShopItem['size_id'].push(
        {
          'size_id':'',
          'size_name':'',
          'price':sale_price,
          'soldout':soldout
        }
      );
    }

    return mumoShopItem;
  }

  // 商品情報を取得
  async getData() {
    // 普通の商品を取得
    const data = await this.getNomalData();

    // スプレットシートの項目順番
    let cnt = 1;

    // 行事のデータを取得
    let dataItems = data.split('\n');

    // スプレットシートの行事のデータを格納
    for (let dataItem of dataItems) {
      // 項目事のデータを取得
      let datas = dataItem.split('|');

      // データが存在するときに
      if (datas.length !== 1) {
        // グッズIDを取得
        let goods_id = datas[1];
        // mu-mo shopの商品番号
        let merc_no = datas[2];
        /*
        if (merc_no !== '') {

          ////////////////////////////
          // mu-mo SHOPの商品番号が指定されている場合
          ////////////////////////////

          // 同一goods_idが存在していな場合
          // ※sku個別商品で検索を行っても、sku商品としてデータを取得します
          if (!(goods_id in this.normalGoodItems)) {
            // mu-mo SHOP の商品情報を取得
            let mumoShopItemData = await this.getMumoShopApi(merc_no);

            // ステータスが正常(0)の時
            if (mumoShopItemData.status === 0) {
              // mu-mo SHOPの商品情報を取得
              this.normalGoodItems[goods_id] = this.getMumoShopItem(datas, mumoShopItemData, cnt);

              cnt++;
            }
          }

        } else
        */
        if (goods_id in this.normalGoodItems) {

          ////////////////////////////
          // 既に同一goods_idが存在している場合には、sku違いとみなしてsize_id部分のみ設定
          ////////////////////////////

          // 売り切れフラグ
          let soldout = '';
          // 表示しない場合
          if (datas[0] === '0') {
            soldout = 'soldout';
          }

          // sku商品(サイズの選べる商品)のサイズ情報を取得
          this.normalGoodItems[goods_id]['size_id'].push({
            'size_id':datas[4],
            'size_name':datas[5],
            'price':datas[6],
            'soldout':soldout
          });

        } else {

          ////////////////////////////
          // 同一goods_idが存在しない場合は新規商品として設定
          ////////////////////////////

          this.normalGoodItems[goods_id] = {};
          // スプレットシートの項目順番を取得
          this.normalGoodItems[goods_id]['item_no'] = cnt;
          // 画面表示用グッズ名
          this.normalGoodItems[goods_id]['goods_name'] = datas[3];

          // 売り切れフラグ
          let soldout = '';
          // 表示しない場合
          if (datas[0] === '0') {
            soldout = 'soldout';
          }

          // グッズサイズ
          this.normalGoodItems[goods_id]['size_id'] = [];
          this.normalGoodItems[goods_id]['size_id'].push({
            'size_id':datas[4],
            'size_name':datas[5],
            'price':datas[6],
            'soldout':soldout
          });

          // 詳細画面用商品説明文
          this.normalGoodItems[goods_id]['description'] = datas[7];
          // 詳細画面用商品説明文 その２
          this.normalGoodItems[goods_id]['extra'] = datas[8];
          // グッズ画像ファイル名
          // httpsから始まる画像が登録されていた場合、httpsから画像を参照
          this.normalGoodItems[goods_id]['goods_image'] = datas[9].startsWith('https') ? datas[9] : "/assets/images/" + datas[9];


          // mu-mo shopの商品番号 と画像が空でないとき
          if (merc_no !== '' && datas[9] === '') {
            // mu-mo SHOP の商品情報を取得
            let mumoShopItemData = await this.getMumoShopApi(merc_no);

            // ステータスが正常(0)の時
            if (mumoShopItemData.status === 0) {
              // mu-mo SHOP の画像を参照
              this.normalGoodItems[goods_id]['goods_image'] = mumoShopItemData.images[0].image_url;
            }
          }

          cnt++;
        }
      }

    }

    // 商品の中で、スプレットシートの項目順番に該当する商品の値を取得
    for (let goods_id in this.normalGoodItems) {

      ///////////////////////////////
      // 商品の売り切れ状況確認処理
      ///////////////////////////////

      // 売れきれフラグを有り状態にしておく
      let soldoutFlg = 'soldout';

      // サイズのある商品の売り切れ状況を確認
      for (var normalGoodItem of this.normalGoodItems[goods_id]['size_id']) {
        // 売り切れ商品でない場合
        if (normalGoodItem.soldout === ''){
          // 売り切れフラグを無にする
          soldoutFlg = '';
        }
      }

      // 売り切れ表示
      this.normalGoodItems[goods_id]['soldout'] = soldoutFlg;

      // 商品の追加
      this.normalData.push(this.normalGoodItems[goods_id]);
    }

  }

}

export default NormalData;
