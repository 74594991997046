import React from 'react'

class SpecialGoodsComponent extends React.Component {
  constructor(props){
    super(props);
    // 特別な商品の表示フラグ
    this.specialFlg = props.specialFlg;
    // 特別な商品の値
    this.specialGoodItem = props.specialGoodItem;
  }

  render() {
    return (
      <div>
        {/* 特別商品の表示 */}
        {(this.specialFlg === true) &&
          <div class="special">
            <dl>
              <dt>
                <span class={this.specialGoodItem.soldout} style={{backgroundImage: `url("${this.specialGoodItem.goods_image}")`}}></span>
              </dt>
              <dd>
                <h2 dangerouslySetInnerHTML={{__html: this.specialGoodItem.goods_name}}></h2>
                <p dangerouslySetInnerHTML={{__html: this.specialGoodItem.description}}></p>
              </dd>
            </dl>
          </div>
        }
      </div>
    );
  }
}

export default SpecialGoodsComponent;
