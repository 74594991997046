import React from 'react'
import DocumentMeta from 'react-document-meta';
import '../App.css';

import SpecialGoodsComponent from './parts/SpecialGoodsComponent'
import NormalGoodsComponent from './parts/NormalGoodsComponent'

import SpecialData from '../js/SpecialData'
import NormalData from '../js/NormalData'
import DaysData from '../js/DaysData'

import Setting from '../setting.json';

class PageMainComponent extends React.Component {

  constructor(props){
    super(props);

    ////////////////////////
    // 設定ファイルから、アーテイスト名の公演情報を取得
    ////////////////////////

    // 対象のアーテイスト名
    //let urlAetist = props.match.params.artist;

    // 固定URLにした為、URLを指定
    let urlAetist = "nissy";

    // 設定情報(json)の取得
    let settingObj = Setting.data[urlAetist];
    // 値が空の場合、デフォルト設定をいれる
    if (settingObj === undefined) {
      settingObj = Setting.data['none'];
    }

    // ライブ日時取得オブジェクト
    this.daysDataObj = new DaysData( settingObj['daysApi'] );
    this.daysDataObj.getData();

    ////////////////////////
    // 公演日時を取得
    ////////////////////////

    // 対象の公演日時
    this.urlTargetDate = this.checkTargetDate( props.match.params.targetDate );

    // 現在日時
    this.urlCurrentDate = this.checkCurrentDate( props.match.params.currentDate );

    ////////////////////////
    // 公演に関する商品情報等を取得
    ////////////////////////

    var specialApi = "";
    var normalApi = "";

    var shop_item_flg = 0;
    // 埼玉公演
    if (this.urlTargetDate === "20241116") {
      specialApi = settingObj['saitama-1-specialApi'];
      normalApi = settingObj['saitama-1-normalApi'];
      shop_item_flg = 1;

    } else if (this.urlTargetDate === "20241117") {
      specialApi = settingObj['saitama-2-specialApi'];
      normalApi = settingObj['saitama-2-normalApi'];
      shop_item_flg = 1;

    }

/*
    // 名古屋公演
    if (this.urlTargetDate === "20221112") {
      specialApi = settingObj['nagoya-1-specialApi'];
      normalApi = settingObj['nagoya-1-normalApi'];

    } else if (this.urlTargetDate === "20221113") {
      specialApi = settingObj['nagoya-2-specialApi'];
      normalApi = settingObj['nagoya-2-normalApi'];
    }

    // 福岡公演
    if (this.urlTargetDate === "20221224") {
      specialApi = settingObj['fukuoka-1-specialApi'];
      normalApi = settingObj['fukuoka-1-normalApi'];

    } else if (this.urlTargetDate === "20221225") {
      specialApi = settingObj['fukuoka-2-specialApi'];
      normalApi = settingObj['fukuoka-2-normalApi'];

    }

    // 大阪公演
    if (this.urlTargetDate === "20230204") {
      specialApi = settingObj['oosaka-1-specialApi'];
      normalApi = settingObj['oosaka-1-normalApi'];
    } else if (this.urlTargetDate === "20230205") {
      specialApi = settingObj['oosaka-2-specialApi'];
      normalApi = settingObj['oosaka-2-normalApi'];
    }

    // 東京公演
    if (this.urlTargetDate === "20230216") {
      specialApi = settingObj['tokyo-1-specialApi'];
      normalApi = settingObj['tokyo-1-normalApi'];

    } else if (this.urlTargetDate === "20230217") {
      specialApi = settingObj['tokyo-2-specialApi'];
      normalApi = settingObj['tokyo-2-normalApi'];

    }

    // 北海道公演
    if (this.urlTargetDate === "20230325") {
      specialApi = settingObj['hokkaido-1-specialApi'];
      normalApi = settingObj['hokkaido-1-normalApi'];
    }
*/

    if( shop_item_flg === 1 ){
      // 特別な商品取得オブジェクト
      this.specialDataObj = new SpecialData({
        "specialApi":specialApi,
        "mumoShopApi":Setting.api['mumoShopApi']
      });
      this.specialDataObj.getData();

      // 普通の商品取得オブジェクト
      this.normalDataObj = new NormalData({
        "normalApi":normalApi,
        "mumoShopApi":Setting.api['mumoShopApi']
      });
      this.normalDataObj.getData();
    }

    // header情報のメタタグ情報
    this.metaText = settingObj['meta'];

    ////////////////////////
    // その他の変数の初期化
    ////////////////////////

    // エラーメッセージ
    this.errMsg = [];

    // 描画回数のカウントで使用
    this.state = {
      renderCount: 0,
    };

  }

  // 対象の公演日のフォーマットチェック
  checkTargetDate(date){
    if (date !== undefined) {
      if( date !== null && date.length === 8 && !isNaN(date) ) {
        return date;
      }
    }

    return '';
  }

  // 対象の比較日(本日の日付)のフォーマットチェック
  checkCurrentDate(date){
    if (date !== undefined) {
      if( date !== null && date.length === 8 && !isNaN(date) ) {
        return date;
      }
    }

    // 本日の日付を取得
    let currentDate = this.daysDataObj.setFormatDate( new Date() );
    return currentDate;
  }

  // エラーメッセージを取得
  getErrMsg() {
    // エラーメッセージ
    let errMsg = [];

    // 対象の公演の日付の確認
    if (this.urlTargetDate === '') {
      errMsg.push('指定された日付が不正です。');
    }

    // 公演一覧の値が空で無いとき(日付情報を取得後に実施)
    if (Object.keys(this.daysDataObj.liveItems).length !== 0) {
      // 公演情報を取得
      let liveItem = this.daysDataObj.liveItems[this.urlTargetDate];

      // 対象の公演が存在するとき
      if (liveItem !== undefined) {
        if ( liveItem['start_date'] > this.urlCurrentDate ) {
          // 「サイト表示開始日時」 が未来の日付
          errMsg.push('公演当日までお待ち下さい。');

        } else if( liveItem['end_date'] <= this.urlCurrentDate ) {
          // 「サイト表示終了日時」 が過去の日付
          errMsg.push('本公演は終了しました。');

        }
      } else {
        errMsg.push('公演日ではありません。');

      }
    }

    this.errMsg = errMsg;
  }

  // エラーメッセージを取得
  getDatePlaceText() {
    // 対象の公演の配列番号
    let datePlace = '';

    // 公演一覧の値が空で無いとき(日付情報を取得後に実施)
    if (Object.keys(this.daysDataObj.liveItems).length !== 0) {
      // 公演情報を取得
      let liveItem = this.daysDataObj.liveItems[this.urlTargetDate];

      // 対象の公演が存在するとき
      if (liveItem !== undefined) {
        // 日付と販売状況を表示
        datePlace = liveItem['name'] + "　グッズ販売状況 ";
      }
    }

    return datePlace;
  }

  // おまじない処理(初期画面表示で使用)
  componentDidMount() {
    const intervalId = setInterval(() =>{
      // エラーとなるので、一度変数に値を格納
      let currentRenderCount = this.state.renderCount;
      this.setState({ renderCount: currentRenderCount += 1});

      // タイマーの設定解除処理
      // 画面の再描画を行ったところで、再描画を終了としています。
      if(this.state.renderCount > 20){
        clearInterval(intervalId); //intervalIdをclearIntervalで指定している
      }},
      1000
    );
  }

  // おまじない処理(初期画面表示で使用)
  componentWillUnmount() {
  }
  
  render() {
    return (
<DocumentMeta {...this.metaText}>
<div>
    <div class="loading"></div>
    <div id="page">
      <header class="page">
        <div class="header">
          {/* 一番上のところ、アーティスト名のアイコン表示 */}
          <h1><p><span><img src="/assets/images/logo-nissy-10anniversaray.png" alt="Nissy" /></span></p></h1>
          {/* ライブ名表示 */}
          {/*<h2><p><span>Nissy Entertainment 4th LIVE 〜DOME TOUR〜</span></p></h2>*/}
        </div>
      </header>

      <div class="page" style={{ margin: '-2px' }}>
        <main>
          <section class="goods">
            <div class="section">
              
              {/* エラーメッセージの取得 */}
              {( this.getErrMsg() )}

              <header class="goods">
                {/* 一番上のところ、アーティスト名のアイコン表示 */}
                <h1><p><span style={{ color: '#f7f1d7' }}>{this.getDatePlaceText()}</span></p></h1>
                <div>
                  {/* エラーメッセージが空でないとき */}
                  {(this.errMsg.length !== 0 ) &&
                    <h2>
                      <p>
                        {this.errMsg[0]}
                      </p>
                    </h2>
                  }
                </div>

                <ul>
                  <li>
                    <a href="https://nep-bazaar.jp/" target="_blank" rel="noreferrer" style={{ color: '#f7f1d7' }}>Nissy Entertainment Bazaar</a>
                  </li>
                  <li>
                    <a href="https://nissy.jp/" target="_blank" rel="noreferrer" style={{ color: '#f7f1d7' }}>Nissy オフィシャルサイト</a>
                  </li>
                </ul>

                <div>
                  {/* エラーメッセージが空のいとき */}
                  {(this.errMsg.length === 0 ) &&
                    <h2 style={{ background: '#fff', color: '#cc0000'}}>
                      <p>
                        ※アイテムごとの購入制限については会場に掲出されているグッズ一覧にてご確認ください。
                      </p>
                    </h2>
                  }
                </div>

              </header>

              {/* エラーメッセージが空のときのみ、商品を表示 */}
              {(this.errMsg.length === 0 ) &&
                <div class="goods">
                  {/* 特別商品の表示 */}
                  {this.specialDataObj.specialData.map((specialGoodItem, index) => (
                    <SpecialGoodsComponent specialFlg={this.specialDataObj.specialFlg} specialGoodItem={specialGoodItem} key={index} />
                  ))}

                  {/* 普通の商品の表示 */}
                  <ul>
                    {this.normalDataObj.normalData.map((normalGoodItem, index) => (
                      <NormalGoodsComponent normalGoodItem={normalGoodItem} key={index} />
                    ))}
                  </ul>
                </div>
              }

            </div>
          </section>
        </main>
      </div>

    </div>
</div>
</DocumentMeta>
    );
  }
}

export default PageMainComponent;
