import React from 'react'
import Modal from "react-modal";

import DetailComponent from '../DetailComponent'

// Modal設定
const customStyles = {
  overlay: {
    zIndex: "100",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    height: "100%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement('#root') //任意のアプリを設定する　create-react-appなら#root

// <br/>がふくまれている場合、html上で改行を行う
const MultiLineBody = ({ body }: { body: string }) => {
  const texts = body.split('<br/>').map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item}
        <br/>
      </React.Fragment>
    );
  });
  return <div>{texts}</div>;
};

class NormalGoodsComponent extends React.Component {
  constructor(props) {
    super(props);
    // 普通の商品の値
    this.normalGoodItem = props.normalGoodItem;

    this.state ={
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // Modal
  openModal() {
    this.setState({modalIsOpen: true});
  }
  afterOpenModal() {
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  
  render() {
    return (
      <li>
        {/* 商品画像の表示 */}
        <div class="photo">
          <button onClick={this.openModal} class={"fancyDetail "+this.normalGoodItem.soldout} style={{backgroundImage: `url("${this.normalGoodItem.goods_image}")`}}></button>
        </div>

        {/* 単独商品画像の表示 */}
        <div class="meta">
          <p dangerouslySetInnerHTML={{__html: this.normalGoodItem.goods_name}}></p>
          {(this.normalGoodItem.size_id.length === 1) &&
            <div>
              {this.normalGoodItem.size_id.map((size_id, index) => (
                <p key={index}>{Number(size_id.price).toLocaleString()}円<span>（税込）</span></p>
              ))}
            </div>
          }
        </div>

        {/* 複数商品画像の表示 */}
        {(this.normalGoodItem.size_id.length > 1) &&
          <div class="type">
            <table>
              <tbody>
                {this.normalGoodItem.size_id.map((size_id, index) => (
                  <tr key={index}>
                    <th><MultiLineBody body={size_id.size_name} /></th>
                    <td class={size_id.soldout}></td>
                    <td>{Number(size_id.price).toLocaleString()}円<span>（税込）</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal" 
          >
            <div class="modalbox">
              <DetailComponent item={this.normalGoodItem}/>
              <div class="closeButtonArea">
                <span><button onClick={this.closeModal}>閉じる</button></span>
              </div>
            </div>

          </Modal>
        </div>
      </li>      
    );
  }
}

export default NormalGoodsComponent;
